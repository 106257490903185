import Vue from "vue";
import Router from "vue-router";



Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/login",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "login",
          path: "login",
          component: () => import("@/views/authentication/Login_page"),
        },
        {
          name: "forgot_password",
          path: "/forgot_password",
          component: () => import("@/views/authentication/forgot_password"),
        },{
          name: "reset forgot password",
          path: "/reset_forgot_password/:token",
          component: () => import("@/views/authentication/password_update"),
        },
      
        
      ],
    },
    {
      name: "reset forgot password",
      path: "/reset_forgot_password/:token",
      component: () => import("@/views/authentication/forgot_password"),
    },
    {
      path: "/dashboard",
      component: () => import("@/layouts/full-layout/FullLayout"),
      children: [ 
        {
          name: "dashboard",
          path: "/dashboard",
          component: () => import("@/views/Dashboard/Dash_index"),
          meta: {
            requiresAuth: true ,
          },
          children:[
           {
                path: '/dashboard',
                name: "dashboard",
                component: () => import("@/views/Dashboard/Contract/pass_index"),
                props: true
           },
           {
                path: '/dashboard/kyc',
                name: 'kyc',
                component: () => import("@/views/Dashboard/Contract/kyc_verify"),
                props: true
            },
            {
                path: '/dashboard/billing_info',
                name: 'billing info',
                component: () => import("@/views/Dashboard/Contract/billing_info"),
                props: true
            },
            {
                path: '/dashboard/summary_contruct',
                name: 'Summary Contruct',
                component: () => import("@/views/Dashboard/Contract/summary_contruct"),
                props: true
            },
            {
                path: '/dashboard/contruct_complete',
                name: 'Contruct',
                component: () => import("@/views/Dashboard/Contract/contruct_complete"),
                props: true
            },
          ]
        },
        /* Venue */
        {
          name: "Venues Select",
          path: "/venues/add-parent-child-venue",
          component: () => import("@/views/venues/venue_modal"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Add Parent Venue",
          path: "/venues/add-parent-venue",
          component: () => import("@/views/venues/venue_create"),
          meta: {
            requiresAuth: true ,
          },
        }, 
        {
          name: "Edit Parent Venue",
          path: "/venues/edit-parent-venue/:id",
          component: () => import("@/views/venues/venue_edit"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "View Parent Venue",
          path: "/venues/view-parent-venue/:id",
          component: () => import("@/views/venues/venue_view"),
          meta: {
            requiresAuth: true ,
          },
        },
      
        {
          name: "Venues",
          path: "/venues",
          component: () => import("@/views/venues/venue_index"),
          meta: {
            requiresAuth: true ,
          },
        },
        /* Venue */
        {
          name: "Child venue Create",
          path: "/venues/Child_venue/index/",
          component: () => import("@/views/venues/Child/Create/child_index"),
          meta: {
            requiresAuth: true ,
          },
          children: [
            {
                path: '/venues/Child_venue/index/',
                name: 'Child venue Create',
                component: () => import("@/views/venues/Child/Create/child_overview"),
                props: true
            }, 
            {
                path: '/venues/Child_venue/time_shift/:id',
                name: 'Time Shift',
                component: () => import("@/views/venues/Child/Create/time_shift"),
                props: true
            },
            {
                path: '/venues/Child_venue/price_shift/:id',
                name: 'Price Shift',
                component: () => import("@/views/venues/Child/Create/price_shift"),
                props: true
            },
            {
                path: '/venues/Child_venue/capacity/:id',
                name: 'Capacity',
                component: () => import("@/views/venues/Child/Create/Capacity_index"),
                props: true
            }, 
            {
                path: '/venues/Child_venue/gallery/:id',
                name: 'Gallery',
                component: () => import("@/views/venues/Child/Create/gallery_index"),
                props: true
            },
            {
                path: '/venues/Child_venue/amenties/:id',
                name: 'Amenties',
                component: () => import("@/views/venues/Child/Create/amenties_index"),
                props: true
            },
            {
                path: '/venues/Child_venue/add-on/:id',
                name: 'Add-ON',
                component: () => import("@/views/venues/Child/Create/addOn_index"),
                props: true
            },
            {
                path: '/venues/Child_venue/terms_condition/:id',
                name: 'Terms-condition',
                component: () => import("@/views/venues/Child/Create/terms_condition"),
                props: true
            },
          ]
        },
        {
          name: "Child venue Edit",
          path: "/venues/Child_venue/eindex/:id",
          component: () => import("@/views/venues/Child/Edit/child_index"),
          meta: {
            requiresAuth: true ,
          },
          children: [
            {
                path: '/venues/Child_venue/eindex/:id',
                name: 'Child venue Create',
                component: () => import("@/views/venues/Child/Edit/child_overview"),
                props: true
            }, 
            {
                path: '/venues/Child_venue/etime_shift/:id',
                name: 'Time Shift',
                component: () => import("@/views/venues/Child/Edit/time_shift"),
                props: true
            },
            {
                path: '/venues/Child_venue/eprice_shift/:id',
                name: 'Price Shift',
                component: () => import("@/views/venues/Child/Edit/price_shift"),
                props: true
            },
            {
                path: '/venues/Child_venue/ecapacity/:id',
                name: 'Capacity',
                component: () => import("@/views/venues/Child/Edit/Capacity_index"),
                props: true
            }, 
            {
                path: '/venues/Child_venue/egallery/:id',
                name: 'Gallery',
                component: () => import("@/views/venues/Child/Edit/gallery_index"),
                props: true
            },
            {
                path: '/venues/Child_venue/eamenties/:id',
                name: 'Amenties',
                component: () => import("@/views/venues/Child/Edit/amenties_index"),
                props: true
            },
            {
                path: '/venues/Child_venue/eadd-on/:id',
                name: 'Add-ON',
                component: () => import("@/views/venues/Child/Edit/addOn_index"),
                props: true
            },
            {
                path: '/venues/Child_venue/eterms_condition/:id',
                name: 'Terms-condition',
                component: () => import("@/views/venues/Child/Edit/terms_condition"),
                props: true
            },
          ]
        },
        {
          name: "Child venue View",
          path: "/venues/Child_venue/vindex/:id",
          component: () => import("@/views/venues/Child/View/child_index"),
          meta: {
            requiresAuth: true ,
          },
          children: [
            {
                path: '/venues/Child_venue/vindex/:id',
                name: 'Child venue Create',
                component: () => import("@/views/venues/Child/View/child_overview"),
                props: true
            }, 
            {
                path: '/venues/Child_venue/vtime_shift/:id',
                name: 'Time Shift',
                component: () => import("@/views/venues/Child/View/time_shift"),
                props: true
            },
            {
                path: '/venues/Child_venue/vprice_shift/:id',
                name: 'Price Shift',
                component: () => import("@/views/venues/Child/View/price_shift"),
                props: true
            },
            {
                path: '/venues/Child_venue/vcapacity/:id',
                name: 'Capacity',
                component: () => import("@/views/venues/Child/View/Capacity_index"),
                props: true
            }, 
            {
                path: '/venues/Child_venue/vgallery/:id',
                name: 'Gallery',
                component: () => import("@/views/venues/Child/View/gallery_index"),
                props: true
            },
            {
                path: '/venues/Child_venue/vamenties/:id',
                name: 'Amenties',
                component: () => import("@/views/venues/Child/View/amenties_index"),
                props: true
            },
            {
                path: '/venues/Child_venue/vadd-on/:id',
                name: 'Add-ON',
                component: () => import("@/views/venues/Child/View/addOn_index"),
                props: true
            },
            {
                path: '/venues/Child_venue/vterms_condition/:id',
                name: 'Terms-condition',
                component: () => import("@/views/venues/Child/View/terms_condition"),
                props: true
            },
          ]
        },
        {
          name: "vendor_profile",
          path: "/vendor_profile",
          component: () => import("@/views/Profile/vendor_profile"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Add-Ons",
          path: "/venues/manage_addon",
          component: () => import("@/views/Add-On/Add_on_index"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Packages",
          path: "/venues/manage_package",
          component: () => import("@/views/Package/Package_index"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Add Packages",
          path: "/venues/add_packages",
          component: () => import("@/views/Package/add_packages"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Edit Packages",
          path: "/venues/edit_package/:id",
          component: () => import("@/views/Package/edit_packages"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Venue Setting",
          path: "/venues/venuesetting/:id",
          component: () => import("@/views/Setting/child_venue_setting"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "calendar",
          path: "/calendar",
          component: () => import("@/views/Calendar/calendar_index"),
          meta: {
            requiresAuth: true ,
          },
        }, 
        {
          name: "add-booking",
          path: "/bookings/add-booking",
          component: () => import("@/views/Booking/Create_booking"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "my-booking",
          path: "/bookings/my-booking",
          component: () => import("@/views/Booking/view_booking"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "My-Booking",
          path: "/bookings/my-booking/invoice/:id",
          component: () => import("@/views/Booking/booking_summary"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Edit-Booking",
          path: "/bookings/edit-booking/:id/:token",
          component: () => import("@/views/Booking/Edit_booking"),
          meta: {
            requiresAuth: true ,
          },
        }, 
        {
          name: "Booking-invoive",
          path: "/bookings/booking_invoive/:id",
          component: () => import("@/views/Booking/booking_invoive"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Manage-Booking",
          path: "/bookings/manage-booking",
          component: () => import("@/views/Booking/Manage_booking"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Manage-Booking-view",
          path: "/bookings/search_logs/:id",
          component: () => import("@/views/Booking/search_logs"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Draft Booking",
          path: "/bookings/drafts-booking",
          component: () => import("@/views/Booking/Draft/draft_booking"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Draft Booking view",
          path: "/bookings/draft_details/:id",
          component: () => import("@/views/Booking/Draft/draft_details"),
          meta: {
            requiresAuth: true ,
          },
        }, 
        {
          name: "Draft Booking Print",
          path: "/bookings/view_draft_details/:id",
          component: () => import("@/views/Booking/Draft/view_draft_details"),
          meta: {
            requiresAuth: true ,
          },
        }, 
        {
          name: "Draft Booking Edit",
          path: "/bookings/draft_booking_edit/:id",
          component: () => import("@/views/Booking/Draft/Edit_booking"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Historical",
          path: "/bookings/historical_tab",
          component: () => import("@/views/Booking/History/historical_tab"),
          meta: {
            requiresAuth: true ,
          },
        },///finance/finance_modules
        {
          name: "Historical Details",
          path: "/bookings/historical_tab/history_details/:id",
          component: () => import("@/views/Booking/History/history_details"),
          meta: {
            requiresAuth: true ,
          },
        },///finance/finance_modules
        
        {
          name: "Finance",
          path: "/finance/finance_modules",
          component: () => import("@/views/Finance/finance_index"),
          meta: {
            requiresAuth: true ,
          },
          children: [
            {
                path: '/finance/finance_modules',
                name: 'Proforma Invoice',
                component: () => import("@/views/Finance/finance_base_price"),
                props: true
            }, 
            {
                path: '/finance/block_Invoice',
                name: 'Block Invoice',
                component: () => import("@/views/Finance/block_Invoice"),
                props: true
            },
            {
                path: '/finance/Quotation_invoice',
                name: 'Quotation',
                component: () => import("@/views/Finance/Quotation_invoice"),
                props: true
            },
            {
                path: '/finance/Invoice_list',
                name: 'Invoice',
                component: () => import("@/views/Finance/Invoice_list"),
                props: true
            }, 
            {
                path: '/finance/VB_Invoice',
                name: 'VB Invoice',
                component: () => import("@/views/Finance/VB_Invoice"),
                props: true
            },  
           
          ]
        },
        {
          name: 'VB Invoice List',
          path: '/vbinvoice_list/:id/:id1',
          component: () => import("@/views/Finance/VB_Invoice_list"),
          meta: {
            requiresAuth: true ,
          },
        }, 
        {
            name: 'VB Invoice All',
            path: '/vb/vb_invoice/:id',
            component: () => import("@/views/Finance/venue_book_invoice"),
            meta: {
              requiresAuth: true ,
            },
        }, 
        {
          name: "Taxation",
          path: "/finance/taxation",
          component: () => import("@/views/Finance/Tax/taxation_index"),
          meta: {
            requiresAuth: true ,
          },
          children: [
            {
                path: "/finance/taxation",
                name: 'GST',
                component: () => import("@/views/Finance/Tax/Gst_list"),
                props: true
            }, 
            {
                path: "/finance/Tax/view_finance_list/:id",
                name: 'GST View',
                component: () => import("@/views/Finance/Tax/view_finance_list"),
                props: true
            }, 
            {
                path: "/finance/tds",
                name: 'TDS',
                component: () => import("@/views/Finance/Tax/tds_index"),
                props: true
            }, 
            {
                path: "/finance/tax/view_tds_list/:id",
                name: 'TDS View',
                component: () => import("@/views/Finance/Tax/view_tds_list"),
                props: true
            }, 
          ]
        },
        {
          name: "Income & Expense",
          path: "/finance/income-expense",
          component: () => import("@/views/Finance/Income-Expense/Inc_index"),
          meta: {
            requiresAuth: true ,
          },
          children: [
            {
                path: "/finance/income-expense",
                name: 'Income Transaction',
                component: () => import("@/views/Finance/Income-Expense/Income/Income_list"),
                props: true,
                children: [
                  {
                    path: "/finance/income-expense",
                    name: 'Income Transaction',
                    component: () => import("@/views/Finance/Income-Expense/Income/income_entry"),
                    props: true
                  },
                  {
                    path: "/finance/income_expense/Base_price_receipt",
                    name: 'Base Price Receipt',
                    component: () => import("@/views/Finance/Income-Expense/Income/Base_price_receipt"),
                    props: true
                  },
                  {
                    path: "/finance/income_expense/Add_ons_receipt",
                    name: 'Add-ons Receipt',
                    component: () => import("@/views/Finance/Income-Expense/Income/Add_ons_receipt"),
                    props: true
                  },
                  {
                    path: "/finance/income_expense/security_deposit_receipt",
                    name: 'Security Deposit Receipt',
                    component: () => import("@/views/Finance/Income-Expense/Income/security_deposit_receipt"),
                    props: true
                  },
                  {
                    path: "/finance/income_expense/Block_receipt",
                    name: 'Block Receipt',
                    component: () => import("@/views/Finance/Income-Expense/Income/Block_receipt"),
                    props: true
                  }
                ]
                
            },
            {
              name: 'Expense Transaction',
              path: "/finance/income-expense/Expense",
              component: () => import("@/views/Finance/Income-Expense/Expense/expense_list"),
              props: true,
               children: [
                  {
                    path: "/finance/income-expense/Expense",
                    name: 'Expense Transaction',
                    component: () => import("@/views/Finance/Income-Expense/Expense/expense_entry"),
                    props: true
                  }
                ]
            },
          ]
        },
        {
          name: 'Revenue Report',
          path: "/finance/satement_report",
          component: () => import("@/views/Finance/Report/report_list"),
          meta: {
            requiresAuth: true ,
          },
            children: [
              {
                path: "/finance/satement_report",
                name: 'finance',
                component: () => import("@/views/Finance/Report/revenue_report"),
                props: true
              },
              {
                path: "/finance/satement_report/revenue_list/:id",
                name: 'Revenue Report List',
                component: () => import("@/views/Finance/Report/revenue_report_list"),
                props: true
              },
              {
                path: "/finance/satement_report/outstanding_report",
                name: 'Outstanding Report',
                component: () => import("@/views/Finance/Report/outstanding_report"),
                props: true
              },
              {
                path: "/finance/satement_report/receipt_payment_report",
                name: 'Receipt & Payment Report',
                component: () => import("@/views/Finance/Report/receipt_payment_report"),
                props: true
              },
              {
                path: "/finance/satement_report/vb_report",
                name: 'VB Report',
                component: () => import("@/views/Finance/Report/vb_report"),
                props: true
              },
              {
                path: "/finance/satement_report/aging_report",
                name: 'Aging Report',
                component: () => import("@/views/Finance/Report/aging_report"),
                props: true
              }
            ]
        },
       
        {
          name: "Finance Setting",
          path: "/finance/setting",
          component: () => import("@/views/Finance/Setting/finance_setting"),
          meta: {
            requiresAuth: true ,
          },
        }, 
        {
          name: "customers",
          path: "/Customer/my-customers",
          component: () => import("@/views/Customer/customer_index"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "My customers view",
          path: "/Customer/my-customers/view/:id",
          component: () => import("@/views/Customer/customer_view"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Invoice",
          path: "/finance/invoice/:id",
          component: () => import("@/views/Invoice/invoice_list"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "general setting",
          path: "/general_setting",
          component: () => import("@/views/General_Setting/G_setting"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Error",
          path: "/service/error",
          component: () => import("@/views/Error/404_index"),
          meta: {
            requiresAuth: true ,
          },
        },
        {
          name: "Profile",
          path: "/profile",
          component: () => import("@/views/Profile/profile_index"),
          meta: {
            requiresAuth: true
          },
          children: [
          {
            
              path: '/profile',
              name: 'Profile',
              component: () => import("@/views/Profile/Account/acc_index"),
              props: true
          }, 
          {
              path: '/profile/Password',
              name: 'password',
              component: () => import("@/views/Profile/Reset_password/pass_index"),
              props: true
          },
          {
              path: '/profile/notification',
              name: 'notification',
              component: () => import("@/views/Profile/Notification/not_index"),
              props: true
          },
          {
              path: '/profile/kyc',
              name: 'kyc',
              component: () => import("@/views/Profile/Kyc/kyc_index"),
              props: true
          }, 
          {
              path: '/profile/contract',
              name: 'Contract',
              component: () => import("@/views/Profile/Contract/contract_index"),
              props: true
          },
          /* {
              path: '/profile_new_setup/api',
              name: 'api',
              component: () => import("@/views/vendor/profile_new_setup/Api/index"),
              props: true
          }, */
          {
              path: '/profile/teams',
              name: 'teams',
              component: () => import("@/views/Profile/Teams/team_index"),
              props: true
          },
          {
              path: '/profile/teams/teams_details/user_details/:id',
              name: 'teams',
              component: () => import("@/views/Profile/Teams/Teams_details/user_details"),
              props: true
          },
         /*  {
              path: '/profile_new_setup/document',
              name: 'document',
              component: () => import("@/views/vendor/profile_new_setup/Contructs/index"),
              props: true
          }, */
          {
            main:"profile",
              path: '/profile/billing_plans',
              name: 'billing',
              component: () => import("@/views/Profile/Billing/bill_index"),
              props: true
          }
        ]

        },
        
        {
          path: "*",
          redirect: '/dashboard',
          component: () => import("@/views/Dashboard/Dash_index"),
          children: [
            {
              name: "Dashboard",
              path: "/dashboard",
              component: () => import("@/views/Dashboard/Dash_index"),
            },
          ],
        },
      ]
    },
    { 
      path: '/:pathMatch(.*)*', 
      component: () => import("@/views/Error/401_index"),
      meta: {
        requiresAuth: true ,
      },
    },
  ],
});

//import NProgress from "nprogress";


router.beforeResolve((to, from, next) => {

document.title = process.env.VUE_APP_NAME;

  if (!to.name) {
    if (localStorage.getItem('authenticated')) {

      next();
    } else {

      localStorage.clear();
      next({ name: 'login' })
    }
  }

  if (to.matched.some(record => record.meta.AdminAuth)) {
    if(localStorage.getItem('authenticated'))
    {
      var role_type = JSON.parse(localStorage.getItem("loggedUserData")).role_type;
      if (role_type === 'admin') {
        next();
      } else {
        next({ name: 'dashboard' })
      }
    }
   else
   {
    localStorage.clear();
      next({ name: 'login' })
   }
    }
    else  if (to.matched.some(record => record.meta.VendorAuth)) {
      if(localStorage.getItem('authenticated'))
      {
        var role_type_v = JSON.parse(localStorage.getItem("loggedUserData")).role_type;
        if (role_type_v === 'vendor') {
          next();
        } else {
          next({ name: 'dashboard' })
        }
      }
     else
     {
      localStorage.clear();
        next({ name: 'login' })
     }
      }
 else if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('authenticated')) {
      next();
    } else {
      localStorage.clear();
      next({ name: 'login' })
    }
  }
  else {
    if (localStorage.getItem('authenticated')) {
      if (to.name == 'account_verify') {
        next()
      } else {
        next({ name: 'dashboard' })
      }

    }
    else {
      next()
    }
  }

  //next()
/*   var token=localStorage.getItem('access_vendor');

  const options = { path: '/api/notification/'+token }; //Options object to pass into SocketIO

Vue.use(new VueSocketIO({
    debug: false,
    connection: SocketIO('http://localhost:8000',options), //options object is Optional
    vuex: {
      actionPrefix: "SOCKET_",
      mutationPrefix: "SOCKET_"
    }
  })
); */
});


/*  router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
}); */
 
/* router.beforeEach(( next)=>{
  NProgress.done();
  //this.$store.commit('setLoading', true)
  next()
})
router.afterEach(()=>{
  //this.$store.commit('setLoading', false)
}) */



export default router;
